<template>
  <div class="table-mobile-wrapper current-coin">
    <ul class="body">
      <li class="row inner-container full-width">
        <div class="cell name-cell">
          <div class="name-cell__logo"></div>
          <div class="name-cell__full-name">{{ activeCoin.name }}</div>
          <div class="name-cell__short-name">{{ activeCoin.shortName }}</div>
        </div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Price</div>
        <div class="cell value">&#36;{{ activeCoin.price }}</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Volume</div>
        <div class="cell value cell-volume">
          <div class="cell-volume__volume">&#36;{{ activeCoin.volume }}</div>
          <div class="cell-volume__sub-volume">
            &#36;{{ activeCoin.subVolume }}
          </div>
        </div>
      </li>
      <li class="row inner-container">
        <div class="cell header">24H &#37;</div>
        <div class="cell value day-change">{{ activeCoin.day }}&#37;</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">7D &#37;</div>
        <div class="cell value week-change">{{ activeCoin.week }}&#37;</div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CurrentCoinTableMobile',
  computed: {
    ...mapGetters({
      activeCoin: 'coinDetails/activeCoin',
    }),
  },
};
</script>

<style lang="scss" scoped>
.current-coin {
  padding-top: 14px;
  padding-bottom: 26px;

  &.table-mobile-wrapper {
    .body {
      background-color: var(--table-header-background);
      background-image: var(--table-linear-gradient);

      .row {
        background-color: var(--primary-background-color);

        &.full-width {
          height: 100px;
        }

        &:not(:last-child),
        &:not(.full-width) {
          margin-bottom: 1px;
        }

        .cell {
          &.name-cell {
            .name-cell {
              &__logo {
                height: 68px;
                width: 68px;
                margin-right: 22px;
                border-radius: 50%;
                background-color: #c4c4c4;
              }

              &__full-name {
                margin-right: 9px;
                font-family: var(--secondary-font-family);
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0.05em;
              }

              &__short-name {
                margin-top: 3px;
                font-size: 19px;
                font-style: italic;
                color: var(--green-100);
                text-transform: uppercase;
              }
            }
          }

          &.cell-volume {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            line-height: 1.1;

            .cell-volume {
              &__sub-volume {
                font-size: 15px;
                color: var(--green-100);
                font-style: italic;
              }
            }
          }

          &.day-change {
            color: var(--success);
          }

          &.week-change {
            color: var(--error);
          }

          &.day-change,
          &.week-change {
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
