<template>
  <a class="sign-up-link" href="" v-on="$listeners">
    <span class="sign-up-link__content">Sign up</span>
  </a>
</template>

<script>
export default {
  name: 'SignUpLink',
};
</script>

<style lang="scss" scoped>
.sign-up-link {
  height: 32px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid var(--green-700);
  color: var(--green-700);
  font-family: var(--secondary-font-family);
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;

  @include respond-to('lg') {
    height: 46px;
    padding: 0 24px;
    font-size: 20px;
  }

  &__content {
    display: inline-block;
    margin-top: 3px;
  }
}
</style>
