<template>
  <Carousel class="table-mobile-wrapper exchanges-table" v-bind="carouselProps">
    <Slide v-for="exchange in exchanges" :key="exchange.id" class="body">
      <li class="row inner-container">
        <div class="cell header">Name</div>
        <div class="cell value">{{ exchange.name }}</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Volume</div>
        <div class="cell value">{{ exchange.volume }}</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Coin</div>
        <div class="cell value">{{ exchange.coin }}</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Special offers</div>
        <div class="cell value">{{ exchange.specialOffers }}</div>
      </li>
      <li class="row inner-container">
        <div class="cell header">Sign up</div>
        <div class="cell value">
          <SignUpLink @click.prevent.stop="$router.go(-1)" />
        </div>
      </li>
    </Slide>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import SignUpLink from '@/components/common/SignUpLink.vue';
const exchanges = [
  {
    id: 1,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
  {
    id: 2,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
  {
    id: 3,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
  {
    id: 4,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
  {
    id: 5,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
  {
    id: 6,
    name: 'Bitcoin',
    volume: '56.35B',
    coin: 'Lorem',
    specialOffers: 'Lorem ipsum',
  },
];

export default {
  name: 'CoinExchangesTableMobile',
  components: {
    Carousel,
    Slide,
    SignUpLink,
  },
  data() {
    return {
      exchanges,
      carouselProps: {
        paginationActiveColor: 'var(--primary-font-color)',
        paginationColor: 'var(--table-mobile-pagintaion-color)',
        paginationPadding: 5,
        paginationSize: 6,
        perPage: 1,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.exchanges-table {
  &.table-mobile-wrapper {
    background-color: var(--table-mobile-background-color);

    .body {
      .row {
        &:nth-child(2n) {
          background-color: var(--table-mobile-alternate-row-background-color);
        }
      }
    }
  }
}
</style>

<style lang="scss">
.exchanges-table {
  .VueCarousel-dot-container {
    margin-top: 0 !important;
    margin-bottom: 15px !important;

    .VueCarousel-dot {
      outline: none !important;
    }
  }
}
</style>
