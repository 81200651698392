<template>
  <section class="coin-details">
    <CurrentCoinTableMobile />
    <h4 class="exchanges-title inner-container">Exchanges</h4>
    <CoinExchangesTable />
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import CoinExchangesTable from '@/components/CoinDetails/CurrentDetailsView/CoinExchangesTableMobile.vue';
import CurrentCoinTableMobile from '@/components/CoinDetails/CurrentDetailsView/CurrentCoinTableMobile.vue';

export default {
  name: 'CoinDetailsView',
  components: {
    CoinExchangesTable,
    CurrentCoinTableMobile,
  },
  computed: {
    ...mapGetters({
      activeCoin: 'coinDetails/activeCoin',
      isMobile: 'user/isMobile',
    }),
  },
  watch: {
    isMobile(value) {
      if (!value) {
        this.$router.push('/');
      }
    },
  },
  created() {
    if (!Object.keys(this.activeCoin).length) {
      return this.$router.push('/');
    }
  },
  metaInfo() {
    return {
      titleTemplate: '%s | Coins',
    };
  },
};
</script>

<style lang="scss" scoped>
.coin-details {
  .exchanges-title {
    height: 89px;
    display: flex;
    align-items: center;
    background-color: var(--table-header-background);
    color: var(--light-green-300);
    font-family: var(--secondary-font-family);
    font-size: 24px;
    font-weight: 500;
  }
}
</style>
